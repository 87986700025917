body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img, video {
  max-width: unset;
}

.Node-module_rect__1Eal3 {
  fill: transparent!important;
}

.camera-on-demand-left {
  -webkit-filter: brightness(100%);
}

.camera-on-demand-left:hover {
  border: #0070B7ac solid 2px !important;
  -webkit-filter: brightness(140%);
}

.img-loading {
  animation: loadingAnimation 1.5s infinite;
}

@keyframes loadingAnimation {
  0% {
    -webkit-filter: brightness(50%) contrast(0.7);
  }
  50% {
    -webkit-filter: brightness(100%) contrast(1.1);
  }
  100% {
    -webkit-filter: brightness(50%) contrast(0.7);
  }
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3!important;
}

.width-box {
  background-color: #f6f5f9de;
  border-left: 1px solid #1a242d69;
  margin-left: 2px;
  width: 1px;
  transition: width 0.3s ease-in-out;
}

.card-animation:hover .width-box {
  border-left: 0;
  border-radius: 0;
  width: 3em;
  transition: width 0.3s ease-in-out;
}

.yScrollBarWrapper-grey::-webkit-scrollbar-thumb {
  border-radius: 15px;
  border: 2px solid var(--scrollbarBG) transparent;
}

.loading-effect {
  color: var(--loading-color, #46bf83);
  animation: backgroundBlink 1.5s ease-in-out infinite;
}

@keyframes backgroundBlink {
  0% {
    color: var(--loading-color, #46bf83);
  }
  50% {
    color: var(--loading-color-light, #46bf83AA);
  }
  100% {
    color: var(--loading-color, #46bf83);
  }
}

.ordinal-session-div {
  align-content: center;
  transition: transform 0.3s ease;
}
.ordinal-session-div:hover {
  align-content: start;
}

.ordinal-session-div:hover .delete-box {
  opacity: 1;
  transform: translateY(0);
}

.delete-box {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 32px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.table-cell {
  min-width: 200px;
  white-space: nowrap;
}